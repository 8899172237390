// ** React Imports
import ReactDOM from 'react-dom'
import { IntlProvider } from 'react-intl';
import { LanguageProvider, LanguageContext } from './utility/context/Language';
import { Suspense, lazy } from 'react'
import { BrowserRouter } from 'react-router-dom'

// ** Redux Imports
import { store } from './redux/store'
import { Provider } from 'react-redux'

// ** ThemeColors Context

import { ThemeContext } from './utility/context/ThemeColors'

// ** ThemeConfig
import themeConfig from './configs/themeConfig'

// ** Toast
import { Toaster } from 'react-hot-toast'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Hot Toast Styles
import '@styles/react/libs/react-hot-toasts/react-hot-toasts.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss';
import { createBrowserHistory } from "history";

// ** Service Worker
import * as serviceWorker from './serviceWorker'

export const unProtectedRoutes = [
  "login"
];

export const protectedRoutes = [
  "login",
  "forgot-password",
  "reset-password",
  "check-reset",
  "institute-register",
  "institute-set-password",
  "TI",
];

const history = createBrowserHistory();
const token = localStorage.getItem("accessToken");
const routes = history.location.pathname.split("/");
const unAuthorizedRoutes = unProtectedRoutes.filter((upRoutes) =>
  routes.includes(upRoutes)
)?.length;

if (!token) {
  // store.dispatch(handleLogout());
  if (!unAuthorizedRoutes) {
    history.push("/login");
  }
} else {

  if (unAuthorizedRoutes) {
    history.push("/");
  }
}

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <LanguageProvider>
        <LanguageContext.Consumer>
          {({ locale, messages }) => (
            <IntlProvider locale={locale} messages={messages}>
              <Suspense fallback={<Spinner />}>
                <ThemeContext>
                  <LazyApp />
                  <Toaster position={themeConfig.layout.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
                </ThemeContext>
              </Suspense>
            </IntlProvider>
          )}
        </LanguageContext.Consumer>
      </LanguageProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
