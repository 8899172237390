import React, { createContext, useState, useEffect } from 'react';
import { useRTL } from '@hooks/useRTL'

// Create a context for language management
export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [locale, setLocale] = useState(localStorage.getItem("lang") === 'ar' ? 'ar' : 'en'); // Default language
    const [messages, setMessages] = useState({});
    const [isRtl, setIsRtl] = useRTL()

    useEffect(() => {
        // Load messages based on the selected locale
        const loadMessages = async () => {
            const response = await fetch(`/assets/data/locales/${locale}.json`);
            const data = await response.json();
            setIsRtl(locale === 'ar')
            setMessages(data);
        };

        loadMessages();
    }, [locale]);

    const changeLanguage = (lang) => {
        setLocale(lang);
        localStorage.setItem('lang',lang);
    };

    return (
        <LanguageContext.Provider value={{ locale, messages, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
